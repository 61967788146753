
import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import localforage from 'localforage';
import { Link } from "react-router-dom";
import { Box, List, ListItem, ListItemText, Button } from '@mui/material';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { currentSymbolState, recordsModeState, } from '#state'
import {
  statsListState,
  statsLastSeenId,
  replayMetaState,
} from '#state/data'

import {
  statsFilterEventState,
  statsFilterStratState,
  statsFilterSideState,
  statsFilterWRState,
  statsStartTimeState,
  statsEndTimeState,
} from '#state/gui'

// import { RecordsMode } from './controls/RecordsMode'
// import { BinanceWS } from './BinanceWS';
import { StatsFilterEvent } from './controls/StatsFilterEvent'
import { StatsFilterStrat } from './controls/StatsFilterStrat'
import { StatsFilterSide } from './controls/StatsFilterSide'
import { StatsFilterWR } from './controls/StatsFilterWR'
import { StatsChart } from './charts/StatsChart'
import { getStatsList } from '../utils';

// const defaultFee = 0.001 // 0.00065 // 0.00026
const marketFee = 0.00005 // 0.0005
const limitFee = 0.00002 // 0.0002
const cashbackRate = 0.35

const wrWindowSize = 3;
const winRateThreshold = 0.4;

const initialBalanceDefault = 1000

export function Stats(props) {
  // const mode = useRecoilValue(recordsModeState);
  const mode = 'paper'
  // const filter = 'null' // 'close' || 'null'
  const setCurrentSymbol = useSetRecoilState(currentSymbolState);
  const [statsList, setStatsList] = useRecoilState(statsListState);
  const [lastSeenId, setLastSeenId] = useRecoilState(statsLastSeenId);
  const setReplayMeta = useSetRecoilState(replayMetaState);
  const [reload, setReload] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0)
  const [totalFee, setTotalFee] = useState(0)
  const [totalCashback, setTotalCashback] = useState(0)
  const [totalMFE, setTotalMFE] = useState(0)
  const [totalMAE, setTotalMAE] = useState(0)
  const [stats, setStats] = useState(null)
  // 
  const filterEvent = useRecoilValue(statsFilterEventState)
  const filterStrat = useRecoilValue(statsFilterStratState)
  const filterSide = useRecoilValue(statsFilterSideState)
  const filterWR = useRecoilValue(statsFilterWRState)
  const startTime = useRecoilValue(statsStartTimeState)
  const endTime = useRecoilValue(statsEndTimeState)

  const stratsOn = filterStrat === 'all' ? ['breakout', 'bounce'] : [filterStrat]
  const sideOn = filterSide === 'all' ? ['long', 'short'] : [filterSide]
  const wrOn = filterWR === 'enabled'

  let lastSid = null
  let bgVariant = 0

  useEffect(() => {
    let ignoredKeys = {}

    const run = async () => {
      if (!statsList) return;

      const initStats = () => ({
        count: 0,
        winRate: 0,
        result: 0,
        fee: 0,
        wins: 0,
        win: 0,
        loss: 0,
      });

      const initTimeStats = () => ({
        initBalance: initialBalanceDefault,
        total: initStats(),
        long: initStats(),
        short: initStats(),
        breakout: initStats(),
        bounce: initStats(),
        'breakout:long': initStats(),
        'breakout:short': initStats(),
        'bounce:long': initStats(),
        'bounce:short': initStats()
      });

      const calculateWinRate = (stats) => {
        return stats.count > 0 ? stats.wins / stats.count : 0;
      };

      const calculateStats = (trades, mark) => {
        const stats = initStats();
        trades.forEach(trade => {
          if (mark && trade[mark]) {
            return
          } else if (mark) {
            trade[mark] = true
          }
          stats.result += trade.changeUSD;
          if (trade.isClosed) {
            stats.count++;
            if (trade.changeUSD > 0) {
              stats.wins++;
              stats.win += trade.changeUSD;
            } else {
              stats.loss += Math.abs(trade.changeUSD);
            }
          }
        });
        stats.winRate = calculateWinRate(stats);
        return stats;
      };

      const addTrade = (trade, tradeHistory) => {
        tradeHistory.push(trade);
        if (tradeHistory.length > wrWindowSize) {
          tradeHistory.shift();
        }
        return tradeHistory;
      };

      const updateResults = (result, changeUSD, fee, exitPercent) => {
        result.result += changeUSD;
        result.fee += fee;
        if (exitPercent > 0) {
          result.count++;
          if (changeUSD > 0) {
            result.wins++;
            result.win += changeUSD;
          } else {
            result.loss += Math.abs(changeUSD);
          }
          result.winRate = calculateWinRate(result);
        }
      };

      const results = {
        total: initStats(),
        long: initStats(),
        short: initStats(),
        breakout: initStats(),
        bounce: initStats(),
        'breakout:long': initStats(),
        'breakout:short': initStats(),
        'bounce:long': initStats(),
        'bounce:short': initStats(),
        hourly: {},
        minute: {},
        movingWindow: {
          total: initStats(),
          long: initStats(),
          short: initStats(),
          breakout: initStats(),
          bounce: initStats(),
          'breakout:long': initStats(),
          'breakout:short': initStats(),
          'bounce:long': initStats(),
          'bounce:short': initStats(),
        },
        conditional: {
          total: initStats(),
          long: initStats(),
          short: initStats(),
          breakout: initStats(),
          bounce: initStats(),
          'breakout:long': initStats(),
          'breakout:short': initStats(),
          'bounce:long': initStats(),
          'bounce:short': initStats(),
        }
      };

      const tradeHistories = {
        total: [],
        long: [],
        short: [],
        breakout: [],
        bounce: [],
        'breakout:long': [],
        'breakout:short': [],
        'bounce:long': [],
        'bounce:short': [],
        conditional: {
          total: [],
          long: [],
          short: [],
          breakout: [],
          bounce: [],
          'breakout:long': [],
          'breakout:short': [],
          'bounce:long': [],
          'bounce:short': []
        }
      };


      const winRatePeriods = {
        total: [],
        long: [],
        short: [],
        breakout: [],
        bounce: [],
        'breakout:long': [],
        'breakout:short': [],
        'bounce:long': [],
        'bounce:short': []
      };

      const statsListClone = [...statsList];
      statsListClone.sort((a, b) => a.now - b.now);

      let MFE = 0, MAE = 0

      statsListClone.forEach((r, i) => {
        const openTime = (r?.history && r?.history[0]?.time) || 0
        if (!openTime) return
        if (openTime < startTime || r.now > endTime) return;


        const { openPrice, closePrice } = fixPositionPrices(r)

        if (!closePrice || !openPrice) {
          return null
        }

        //if (r.now < startTime || r.now > endTime || !r.closePrice || !r.openPrice) return;
        if (r.action !== 'strategy:close:market') return;

        const direction = r.direction;
        const strategy = (r.strategy === 'breakout' || r.strategy === 'bounce:hedge') ? 'breakout' : 'bounce';
        if (!stratsOn.includes(strategy)) return;
        if (!sideOn.includes(direction)) return;
        if (wrOn && !r.wrEnabled) return;

        if (ignoredKeys[`${r.strategy}:${r.sid}`]) return
        if (r.exitPercent === 1) {
          ignoredKeys[`${r.strategy}:${r.sid}`] = true
        }

        if (r.reason === 'forceClose') {
          return
        }

        const openFee = strategy === 'bounce' ? limitFee : marketFee
        const closeFee = r.reason === 'takeProfit' ? limitFee : marketFee
        const combinedFee = openFee + closeFee
        // const stopFee = combinedFee
        // const takeFee = openFee + limitFee
        // const cbfee = (combinedFee * (1 - cashbackRate))

        const volumeUSD = r.volume * openPrice;
        const change = direction === 'long'
          ? closePrice / openPrice * 100 - 100
          : openPrice / closePrice * 100 - 100;

        const fee = volumeUSD * combinedFee
        // const cashback = fee * cashbackRate

        let changeUSD = volumeUSD * change / 100 // - fee + cashback;
        if (volumeUSD > 0) {
          changeUSD = volumeUSD * change / 100 // - fee + cashback;
        }
        // else {
        //   changeUSD = -volumeUSD * change / 100 // + fee - cashback;
        // }

        if (r.direction === 'long') {
          if (r.maxPrice) {
            const max = Math.max(r.maxPrice, closePrice)
            const mfe = max / (openPrice) * 100 - 100
            const mfeUsd = volumeUSD * mfe / 100 - fee
            MFE += mfeUsd
          }
          if (r.minPrice) {
            const min = Math.min(r.minPrice, closePrice)
            const mae = (openPrice || 0) / min * 100 - 100
            let maeUsd = -volumeUSD * mae / 100 - fee
            MAE += maeUsd
          }
        } else {
          if (r.maxPrice) {
            const max = Math.max(r.maxPrice, closePrice)
            const mae = max / (openPrice) * 100 - 100
            let maeUsd = -volumeUSD * mae / 100 - fee
            MAE += maeUsd
          }
          if (r.minPrice) {
            const min = Math.min(r.minPrice, closePrice)
            const mfe = (openPrice || 0) / min * 100 - 100
            const mfeUsd = volumeUSD * mfe / 100 - fee
            MFE += mfeUsd
          }
        }

        const trade = { changeUSD, isClosed: r.exitPercent > 0, openTime };

        const hourKey = dayjs(r.now).startOf('hour').valueOf();
        if (!results.hourly[hourKey]) {
          results.hourly[hourKey] = initTimeStats();
          if (r.initBalance) results.hourly[hourKey].initBalance = r.initBalance
        }
        const minuteKey = dayjs(r.now).startOf('minute').valueOf();
        if (!results.minute[minuteKey]) {
          results.minute[minuteKey] = initTimeStats()
          if (r.initBalance) results.minute[minuteKey].initBalance = r.initBalance
        }

        const sdKey = `${strategy}:${direction}`;

        // global stats
        updateResults(results.total, changeUSD, fee, r.exitPercent);
        updateResults(results[direction], changeUSD, fee, r.exitPercent);
        updateResults(results[strategy], changeUSD, fee, r.exitPercent);
        updateResults(results[sdKey], changeUSD, fee, r.exitPercent);

        // hourly stats
        const hourlyStats = results.hourly[hourKey];
        updateResults(hourlyStats.total, changeUSD, fee, r.exitPercent);
        updateResults(hourlyStats[direction], changeUSD, fee, r.exitPercent);
        updateResults(hourlyStats[strategy], changeUSD, fee, r.exitPercent);
        updateResults(hourlyStats[sdKey], changeUSD, fee, r.exitPercent);

        // minute stats
        const minuteStats = results.minute[minuteKey];
        updateResults(minuteStats.total, changeUSD, fee, r.exitPercent);
        updateResults(minuteStats[direction], changeUSD, fee, r.exitPercent);
        updateResults(minuteStats[strategy], changeUSD, fee, r.exitPercent);
        updateResults(minuteStats[sdKey], changeUSD, fee, r.exitPercent);

        // rolling window stats
        tradeHistories.total = addTrade(trade, tradeHistories.total);
        tradeHistories[direction] = addTrade(trade, tradeHistories[direction]);
        tradeHistories[strategy] = addTrade(trade, tradeHistories[strategy]);
        tradeHistories[sdKey] = addTrade(trade, tradeHistories[sdKey]);

        results.movingWindow.total = calculateStats(tradeHistories.total);
        results.movingWindow[direction] = calculateStats(tradeHistories[direction]);
        results.movingWindow[strategy] = calculateStats(tradeHistories[strategy]);
        results.movingWindow[sdKey] = calculateStats(tradeHistories[sdKey]);

        // track win rate periods
        const currentWinRates = {
          total: results.movingWindow.total.winRate,
          breakout: results.movingWindow.breakout.winRate,
          bounce: results.movingWindow.bounce.winRate,
          long: results.movingWindow.long.winRate,
          short: results.movingWindow.short.winRate,
          'breakout:long': results.movingWindow['breakout:long'].winRate,
          'breakout:short': results.movingWindow['breakout:short'].winRate,
          'bounce:long': results.movingWindow['bounce:long'].winRate,
          'bounce:short': results.movingWindow['bounce:short'].winRate
        };

        Object.keys(currentWinRates).forEach(key => {
          if (!winRatePeriods[key]) {
            winRatePeriods[key] = [];
          }
          if (currentWinRates[key] >= winRateThreshold) {
            const lastPeriod = winRatePeriods[key].slice(-1)[0];
            if (lastPeriod && lastPeriod.end === undefined) {
              lastPeriod.end = r.now;
            } else {
              winRatePeriods[key].push({ start: r.now });
            }
          } else {
            const lastPeriod = winRatePeriods[key].slice(-1)[0];
            if (lastPeriod && lastPeriod.end === undefined) {
              lastPeriod.end = r.now;
            }
          }
        });
      });

      // // Filter trades based on win rate periods
      // const filteredStatsList = statsListClone.filter(r => {
      //   const sdKey = `${(r.strategy === 'breakout' || r.strategy === 'bounce:hedge') ? 'breakout' : 'bounce'}:${r.direction}`;
      //   if (r.action !== 'strategy:close:market') return false;
      //   const openTime = r?.history && r?.history[0]?.time || 0
      //   return winRatePeriods[sdKey].some(period => openTime >= period.start && (period.end === undefined || openTime <= period.end));
      // });

      // // Update conditional results based on filteredStatsList
      // filteredStatsList.forEach(r => {
      //   const direction = r.direction;
      //   const strategy = (r.strategy === 'breakout' || r.strategy === 'bounce:hedge') ? 'breakout' : 'bounce';
      //   const sdKey = `${strategy}:${direction}`

      //   const { openPrice, closePrice } = fixPositionPrices(r)

      //   const openFee = strategy === 'bounce' ? limitFee : marketFee
      //   const closeFee = r.reason === 'takeProfit' ? limitFee : marketFee
      //   const combinedFee = openFee + closeFee
      //   // const stopFee = combinedFee
      //   // const takeFee = openFee + limitFee
      //   // const cbfee = (combinedFee * (1 - cashbackRate))
      //   const volumeUSD = r.volume * openPrice

      //   const fee = volumeUSD * combinedFee
      //   const cashback = fee * cashbackRate

      //   const change = direction === 'long'
      //     ? r.closePrice / r.openPrice * 100 - 100
      //     : r.openPrice / r.closePrice * 100 - 100;

      //   let changeUSD = volumeUSD * change / 100 - fee + cashback;
      //   if (volumeUSD > 0) {
      //     changeUSD = volumeUSD * change / 100 - fee + cashback
      //   }
      //   // else {
      //   //   changeUSD = -volumeUSD * change / 100 + fee - cashback
      //   // }

      //   updateResults(results.conditional.total, changeUSD, fee, r.exitPercent);
      //   updateResults(results.conditional[direction], changeUSD, fee, r.exitPercent);
      //   updateResults(results.conditional[strategy], changeUSD, fee, r.exitPercent);
      //   updateResults(results.conditional[sdKey], changeUSD, fee, r.exitPercent);
      // });

      // console.log('Filtered Stats List:', filteredStatsList, winRatePeriods);
      console.log('Final results:', results);
      setTotal(results.total.result);
      setTotalFee(results.total.fee);
      setTotalCashback(results.total.fee * cashbackRate);
      setTotalMFE(MFE);
      setTotalMAE(MAE);
      setStats(results);
    };

    run();
  }, [statsList, startTime, endTime, filterStrat, filterSide, filterWR]);



  useEffect(() => {
    const run = async () => {
      // { setStatsList, setLastSeenId, lastSeenId, mode, filter }
      try {
        setLoading(true)
        const sl = await getStatsList({ mode, filter: filterEvent, statsList, setStatsList, setLastSeenId });
        console.log('getStatsList result', sl)
      } catch (error) {
        console.log('getStatsList error', error)
      } finally {
        setLoading(false)
      }
    }
    run();
  }, [reload, filterEvent, mode]);

  const loadMoreHandler = async () => {
    try {
      setLoading(true)
      const sl = await getStatsList({ mode, filter: filterEvent, statsList, setStatsList, setLastSeenId, lastSeenId })
      console.log('getStatsList result', sl)
    } catch (error) {
      console.log('getStatsList error', error)
    } finally {
      setLoading(false)
    }
  }

  let ignoredKeys = {}

  return (
    <>
      <Box sx={{
        mt: 1,
        ml: 1,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '92vw',
        flexDirection: { xs: 'column', lg: 'row' }
      }}>

        {/* <RecordsMode /> */}
        <Box sx={{ mt: 1 }}>
          <Button disabled={loading} onClick={() => setReload(Date.now())}>Reload</Button>
          {lastSeenId ? <Button disabled={loading} onClick={loadMoreHandler}>Load More</Button> : null}
        </Box>

        <ResponsiveDateTimePickers />

        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 1,
        }}>
          <Box sx={{
            mt: { xs: 1, lg: 0 },
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 1,
          }}>
            <StatsFilterEvent />
            <StatsFilterStrat />
            <StatsFilterSide />
            <StatsFilterWR />
          </Box>

        </Box>
      </Box>

      <Box sx={{
        ml: { xs: 1, lg: 2 },
        mt: { xs: 1, lg: 0 },
        // flexBasis: { xs: '100%', lg: '100%' },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 2,
      }}>
        <Box sx={{
          // flexBasis: { xs: '10rem', lg: '10rem' },
          color: (total - totalFee + totalCashback) > 0 ? 'lime' : 'red'
        }}>
          Total: ${(total - totalFee + totalCashback).toFixed(2)}
        </Box>
        <Box sx={{
          // flexBasis: { xs: '10rem', lg: '10rem' },
          color: total > 0 ? 'lime' : 'red'
        }}>
          PNL: ${total.toFixed(2)}
        </Box>
        <Box sx={{
          // flexBasis: { xs: '10rem', lg: '10rem' },
          color: total - totalFee > 0 ? 'lime' : 'red'
        }}>
          Fee: ${totalFee.toFixed(2)}
        </Box>
        <Box sx={{
          // flexBasis: { xs: '10rem', lg: '10rem' },
        }}>
          CB: ${totalCashback.toFixed(2)}
        </Box>
        <Box sx={{
          // flexBasis: { xs: '10rem', lg: '10rem' },
        }}>
          MFE: ${totalMFE.toFixed(2)}
        </Box>
        <Box sx={{
          // flexBasis: { xs: '10rem', lg: '10rem' },
        }}>
          MAE: ${totalMAE.toFixed(2)}
        </Box>
      </Box>

      <Box>
        <StatsChart stats={stats} />
      </Box>

      <List sx={{ overflowY: 'auto' }}>
        {statsList.map((r, i) => {
          const openTime = r?.history && r?.history[0]?.time
          if (i > 500) {
            // console.log('ignored i > 500', i)
            return null
          }

          const { openPrice, closePrice } = fixPositionPrices(r)

          if (!openPrice) {
            console.log('no openPrice', r)
            // return null
          }
          if (!closePrice || !openPrice) {
            console.log('no closePrice', r)
            // return null
          }

          const strategy = (r.strategy === 'breakout' || r.strategy === 'bounce:hedge') ? 'breakout' : 'bounce';
          if (r.action === 'strategy:close:market' && !stratsOn.includes(strategy)) return null;
          
          if(!sideOn.includes(r.direction)) return null

          if (wrOn) {
            if (!r.wrEnabled) return null;
          }

          if ((openTime || r.now) < startTime) {
            console.log('openTime < startTime', r)
            return null
          }
          if ((openTime || r.now) > endTime) {
            console.log('openTime > endTime', r)
            return null
          }

          if (ignoredKeys[`${r.strategy}:${r.sid}`]) {
            console.log('ignoredKeys', r)
            return null
          }
          if (r.exitPercent === 1) {
            ignoredKeys[`${r.strategy}:${r.sid}`] = true
          }

          if (r.sid !== lastSid) {
            bgVariant = ++bgVariant
            lastSid = r.sid
          }

          const openFee = strategy === 'bounce' ? limitFee : marketFee
          const closeFee = r.reason === 'takeProfit' ? limitFee : marketFee
          const combinedFee = openFee + closeFee
          // const stopFee = combinedFee
          // const takeFee = openFee + limitFee
          // const cbfee = (combinedFee * (1 - cashbackRate))

          let change, changeUSD, volumeUSD, fee, cashback
          try {
            if (r.direction === 'long') {
              change = closePrice / openPrice * 100 - 100
              volumeUSD = r.volume * openPrice
              fee = volumeUSD * combinedFee
              cashback = fee * cashbackRate
              changeUSD = volumeUSD * change / 100 // - volumeUSD * cbfee
            } else if (r.direction === 'short') {
              change = openPrice / closePrice * 100 - 100
              volumeUSD = r.volume * openPrice
              fee = volumeUSD * combinedFee
              cashback = fee * cashbackRate
              if (volumeUSD > 0) {
                changeUSD = volumeUSD * change / 100 // - volumeUSD * cbfee
              } else {
                changeUSD = -volumeUSD * change / 100 // + volumeUSD * cbfee
              }
            }
          } catch (error) {

          }

          if (volumeUSD > 10000) {
            console.log('high volume', r, volumeUSD, changeUSD, change)
          }

          if (r.action === 'strategy:close:takeLimit') {
            return null
          } else if (r.action === 'strategy:close:market') {
            if (isNaN(change)) {
              console.log('change is NaN', r)
              return null
            }
            // if (r.reason === 'forceClose') {
            //   return null
            // }
            const slip = r.direction === 'long'
              ? Math.abs(r.delayedLvlPrice - openPrice) / openPrice * 100
              : Math.abs(openPrice - r.delayedLvlPrice) / openPrice * 100

            let mfePrimary = '', mfeSecondary = '', mfeError = false
            if (r.direction === 'long') {
              if (!r.maxPrice) {
                mfePrimary = 'mfe 0%'
                const mfeUsd = -fee
                mfeSecondary = `$${formatNumber(mfeUsd, 2)}`
              } else {
                const max = Math.max(r.maxPrice, closePrice)
                const mfe = max / (openPrice) * 100 - 100
                mfePrimary = `mfe ${(mfe).toFixed(2)}%`

                const mfeUsd = volumeUSD * mfe / 100 - fee
                mfeSecondary = `$${formatNumber(mfeUsd, 2)}`

                if (max !== r.maxPrice) {
                  mfeError = true
                  const mfe = r.maxPrice / (openPrice) * 100 - 100
                  mfePrimary += ` (${(mfe).toFixed(2)}%)`
                  const mfeUsd = volumeUSD * mfe / 100 - fee
                  mfeSecondary += ` ($${formatNumber(mfeUsd, 2)})`
                }
              }
            } else {
              if (!r.minPrice) {
                mfePrimary = 'mfe 0%'
                const mfeUsd = -fee
                mfeSecondary = `$${formatNumber(mfeUsd, 2)}`
              } else {
                const min = Math.min(r.minPrice, closePrice)
                const mfe = (openPrice || 0) / min * 100 - 100
                mfePrimary = `mfe ${(mfe || 0).toFixed(2)}%`

                const mfeUsd = volumeUSD * mfe / 100 - fee
                mfeSecondary = `$${formatNumber(mfeUsd, 2)}`

                if (min !== r.minPrice) {
                  mfeError = true
                  const mfe = (openPrice || 0) / r.minPrice * 100 - 100
                  mfePrimary += ` (${(mfe).toFixed(2)}%)`
                  const mfeUsd = volumeUSD * mfe / 100 - fee
                  mfeSecondary += ` ($${formatNumber(mfeUsd, 2)})`
                }
              }
            }

            let maePrimary = '', maeSecondary = '', maeError = false, maeLvl = false
            if (r.direction === 'long') {
              if (!r.minPrice) {
                maePrimary = 'mae 0%'
                const maeUsd = -fee
                maeSecondary = `$${formatNumber(maeUsd, 2)}`
              } else {
                const min = Math.min(r.minPrice, closePrice)
                const mae = (openPrice || 0) / min * 100 - 100
                maePrimary = `mae ${(mae || 0).toFixed(2)}%`

                let maeUsd = -volumeUSD * mae / 100 - fee

                if (r.minPrice > r.delayedLvlPrice) {
                  maeLvl = true
                  maeUsd = volumeUSD * mae / 100 - fee
                }
                maeSecondary = `$${formatNumber(maeUsd, 2)}`

                // if (min !== r.minPrice) {
                //   maeError = true
                //   const mae = (openPrice || 0) / r.minPrice * 100 - 100
                //   maePrimary += ` (${(mae).toFixed(2)}%)`
                //   const maeUsd = -volumeUSD * mae / 100 - fee
                //   maeSecondary += ` ($${formatNumber(maeUsd, 2)})`
                // }
              }
            } else {
              if (!r.maxPrice) {
                maePrimary = 'mae 0%'
                const maeUsd = -fee
                maeSecondary = `$${formatNumber(maeUsd, 2)}`
              } else {
                const max = Math.max(r.maxPrice, closePrice)
                const mae = max / (openPrice) * 100 - 100
                maePrimary = `mae ${(mae).toFixed(2)}%`

                let maeUsd = -volumeUSD * mae / 100 - fee
                if (r.maxPrice < r.delayedLvlPrice) {
                  maeLvl = true
                  maeUsd = volumeUSD * mae / 100 - fee
                }
                maeSecondary = `$${formatNumber(maeUsd, 2)}`

                // if (max !== r.maxPrice) {
                //   maeError = true
                //   const mae = r.maxPrice / (openPrice) * 100 - 100
                //   maePrimary += ` (${(mae).toFixed(2)}%)`
                //   const maeUsd = -volumeUSD * mae / 100 - fee
                //   maeSecondary += ` ($${formatNumber(maeUsd, 2)})`
                // }
              }
            }

            const initSL = r.INIT_SL_THRESHOLD
            const initSLStr = initSL ? `${formatNumber(initSL * 100, 2)}%` : ''
            const initTP = r.INIT_TP_THRESHOLD
            const initTPStr = initTP ? `${formatNumber(initTP * 2 * 100, 2)}%` : ''

            return (<ListItem
              button
              onClick={() => {
                setCurrentSymbol(r.symbol)
                setReplayMeta(r)
              }}
              // selected={replay && replay._id === r._id}
              key={`rp-${reload}-${r._id}`}
              component={r.insertedId ? Link : undefined}
              to={r.insertedId ? `/replays/${mode}/${r.insertedId}` : undefined}
              sx={{
                display: 'flex', flexDirection: 'column',
                maxWidth: '100vw',
                overflow: 'hidden',
                backgroundColor: bgVariant % 2 ? 'transparent' : '#eeeeff11'
              }}
            >
              <Box sx={{ mt: 0, width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
                <ListItemText
                  primary={`${r.symbol}`}
                  secondary={new Date(r.now).toLocaleString()}
                  sx={{ flexBasis: { xs: '7rem', xl: '12rem' }, mr: 2, color: r.direction === 'long' ? 'lime' : 'red' }} />
                <ListItemText
                  primary={`${r.strategy}`}
                  secondary={`${r.reason} ${Math.round(r.exitPercent * 100)}%`}
                  sx={{ flexBasis: { xs: '10rem', xl: '10rem' }, mr: 2 }} />
                <ListItemText
                  primary={`${formatNumber(change, 2)}%`}
                  secondary={`lvl ${(r.delayedLvlPrice || 0).toPrecision(5)}`}
                  sx={{ flexBasis: { xs: '5rem', xl: '5rem' }, mr: 2, color: change > 0 ? 'lime' : 'red' }} />
                <ListItemText
                  primary={`$${formatNumber(changeUSD, 2)} ($${formatNumber((-fee + cashback), 2)})`}
                  secondary={`$${formatNumber(volumeUSD)}`}
                  sx={{ flexBasis: { xs: '7rem', xl: '7rem' }, mr: { xs: 0, lg: 2 }, color: changeUSD > -fee + cashback ? 'lime' : 'red' }} />
                <ListItemText
                  primary={`open ${(openPrice || 0).toPrecision(5)}`} //  slip ${(slip).toFixed(3)}%
                  secondary={`close ${(closePrice || 0).toPrecision(5)}`}
                  sx={{ flexBasis: '7rem', mr: 2, display: { xs: 'none', lg: 'block' } }} />
                <ListItemText
                  primary={mfePrimary}
                  secondary={mfeSecondary}
                  sx={{ flexBasis: '5rem', mr: 2, display: { xs: 'none', lg: 'block' }, color: mfeError ? 'red' : 'inherit' }}
                />
                <ListItemText
                  primary={maePrimary}
                  secondary={maeSecondary}
                  sx={{ flexBasis: '5rem', mr: 2, display: { xs: 'none', lg: 'block' }, color: maeLvl ? 'lime' : 'inherit' }}
                />
                {/* <ListItemText
                  primary={`touches ${r.touches}`}
                  secondary={`hours ${r.durationMinutes / 60}`}
                  sx={{ flexBasis: '6rem', mr: 2, display: { xs: 'none', lg: 'block' } }}
                /> */}
                <ListItemText
                  primary={`iTP ${initTPStr}`}
                  secondary={`iSL ${initSLStr}`}
                  sx={{ flexBasis: '6rem', mr: 2, display: { xs: 'none', lg: 'block' } }}
                />
                {/* <ListItemText primary={`ATR ${(r.candlePriceActionATR || []).map(a => a.toPrecision(3))}`} secondary={`PA% ${(r.candlePriceActionPercent || []).map(a => a.toPrecision(3))}`} sx={{ mr: 2 }} />*/}


                {/* <ListItemText primary={`${r.symbol}${change ? ' - ' + change + '%' : ''}`} secondary={new Date(r.now).toLocaleString()} /> */}
              </Box>
              {/* <Box sx={{ mt: 0, width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
                <ListItemText
                  primary={`Trades M1: ${formatNumber(totalT_m1, 0, true)} - ${buffer_m1.map(a => formatNumber(a.totalT, 0, true))}`}
                  secondary={`Volume: ${formatNumber(totalV_m1)} - ${buffer_m1.map(a => formatNumber(a.totalV))}`} sx={{ mr: 2 }}
                />
              </Box>
              <Box sx={{ mt: 0, width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
                <ListItemText
                  primary={`Trades S5: ${formatNumber(totalT_s5, 0, true)} - ${buffer_s5.map(a => formatNumber(a.totalT, 0, true))}`}
                  secondary={`Volume: ${formatNumber(totalV_s5)} - ${buffer_s5.map(a => formatNumber(a.totalV))}`} sx={{ mr: 2 }}
                />
              </Box> */}
            </ListItem>)
          } else if (r.action === 'strategy:enter:delayed') {
            // return null
            return (<ListItem
              button
              onClick={() => setReplayMeta(r)}
              // selected={replay && replay._id === r._id}
              key={`rp-${reload}-${r._id}`}
              component={Link}
              to={`/replays/${mode}/${r.insertedId}`}
            >
              <Box sx={{ mt: 0, display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
                <ListItemText primary={`${r.symbol}`} secondary={new Date(r.now).toLocaleString()} sx={{ mr: 2, color: r.direction === 'long' ? 'lime' : 'red' }} />
                {/* <ListItemText primary={`enter`} secondary={``} sx={{ mr: 2 }} /> */}
              </Box>
            </ListItem>)
          } else if (r.action === 'strategy:open:delayed') {
            return null
            return (<ListItem
              button
              onClick={() => setReplayMeta(r)}
              // selected={replay && replay._id === r._id}
              key={`rp-${reload}-${r._id}`}
              component={Link}
              to={`/replays/${mode}/${r.insertedId}`}
              sx={{
                maxWidth: '100vw',
                overflow: 'hidden',
                backgroundColor: bgVariant % 2 ? 'transparent' : '#eeeeff11'
              }}
            >
              <Box sx={{ mt: 0, display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
                <ListItemText primary={`${r.symbol}`} secondary={new Date(r.now).toLocaleString()} sx={{ mr: 2, color: r.direction === 'long' ? 'lime' : 'red' }} />
                <ListItemText primary={`${r.strategy}`} secondary={`Open ${r.direction}`} sx={{ mr: 2 }} />
                {/* <ListItemText primary={`open ${(r.price || 0).toPrecision(5)}`} secondary={`stop ${(r.stop || 0).toPrecision(5)}`} sx={{ mr: 2 }} />
                <ListItemText primary={`$${formatNumber((r.usdAmount || 0))}`} secondary={`Vol. ${formatNumber((r.volume || 0))}`} sx={{ mr: 2 }} />
                <ListItemText primary={`VTargets: ${r.volumeTargets.map(a => formatNumber(a))}`} secondary={``} sx={{ mr: 2 }} /> */}

              </Box>
            </ListItem>)
          } else if (r.action === 'strategy:cancel:delayed') {
            return null
            return (<ListItem
              button
              onClick={() => setReplayMeta(r)}
              // selected={replay && replay._id === r._id}
              key={`rp-${reload}-${r._id}`}
              component={Link}
              to={`/replays/${mode}/${r.insertedId}`}
            >
              <Box sx={{ mt: 0, display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
                <ListItemText primary={`${r.symbol}`} secondary={new Date(r.now).toLocaleString()} sx={{ mr: 2, color: r.direction === 'long' ? 'lime' : 'red' }} />
                <ListItemText primary={`cancel`} secondary={``} sx={{ mr: 2 }} />
              </Box>
            </ListItem>)
          }

          return null
        })}
      </List>
    </>
  );
}

export default Stats;


function LastTrades() {

}


function ResponsiveDateTimePickers() {
  const [startTime, setStartTime] = useRecoilState(statsStartTimeState)
  const [endTime, setEndTime] = useRecoilState(statsEndTimeState)

  const isStartSet = startTime !== 0
  const isEndSet = isFinite(endTime)

  const startDT = isStartSet ? startTime : dayjs().startOf('day').valueOf()
  const endDT = isEndSet ? endTime : dayjs().endOf('day').valueOf()


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        mt: { xs: 1, lg: 0 },
        mx: { xs: 0, lg: 1 },
        width: { xs: '95vw', lg: 'auto' },
      }}>
        <MobileDateTimePicker
          clearable
          label={isStartSet ? 'Start Time' : 'Start Time (not set)'}
          slotProps={{
            textField: {
              size: 'small',
              focused: isStartSet,
              color: isStartSet ? 'success' : 'warning',
            }
          }}
          value={dayjs(startDT)}
          onChange={async (newValue) => {
            const time = newValue.valueOf()
            console.log('setStartTime', time)
            await localforage.setItem('statsStartTimeState', JSON.stringify(time));
            setStartTime(time)
          }}
        />
        <MobileDateTimePicker
          clearable
          label={isEndSet ? 'End Time' : 'End Time (not set)'}
          slotProps={{
            textField: {
              size: 'small',
              focused: isEndSet,
              color: isEndSet ? 'success' : 'warning'
            }
          }}
          value={dayjs(endDT)}
          onChange={(newValue) => {
            console.log('setEndTime', newValue.valueOf())
            setEndTime(newValue.valueOf())
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}

function formatNumber(num, significantDigits = 3) {
  // Включаем массив суффиксов внутрь функции для полной инкапсуляции
  const suffixes = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B',
    1000000000000: 'T'
  };

  const absNum = Math.abs(num);

  if (absNum >= 1000) {
    let scale = 1;
    let suffix = '';
    let scaledNum = num;

    // Определяем нужный делитель и суффикс
    while (scaledNum >= 1000 && scale <= 1e12) {
      scale *= 1000;
      scaledNum = num / scale;
      suffix = suffixes[scale] || '';
    }

    // Округляем число в зависимости от величины
    if (scaledNum >= 100) {
      return (Math.round(scaledNum) + suffix);
    } else if (scaledNum >= 10) {
      return (scaledNum.toFixed(1) + suffix);
    } else {
      return (scaledNum.toFixed(2) + suffix);
    }
  } else if (absNum < 1) {
    // Используем toPrecision для чисел меньше 1
    return num.toPrecision(significantDigits);
  } else {
    // Числа от 1 до 999, применяем логику похожую на большие числа, но без суффикса
    if (num >= 100) {
      return Math.round(num).toString();
    } else if (num >= 10) {
      return num.toFixed(1);
    } else {
      return num.toFixed(2);
    }
  }
}


function fixPositionPrices(r) {
  let positionSize = 0
  let openPrice = r.openPrice

  if (r.history && r.history?.length) {
    const openKey = r.direction === 'long' ? 'openLong' : 'openShort';
    const opens = r.history.filter(a => a.type === openKey)

    let avgPrice = 0
    let totalPosition = 0
    for (const enter of opens) {
      avgPrice += enter.avgEntryPrice * enter.position
      totalPosition += enter.position
    }
    avgPrice /= totalPosition
    openPrice = avgPrice
    positionSize = totalPosition
  }

  // if (openPrice !== r.openPrice) console.log('fixPositionPrices openPrice', openPrice, r.openPrice)


  let closePrice = r.closePrice
  if (r.reason === 'takeProfit') {
    if (r.direction === 'long') {
      // const max = Math.max(r.maxPrice, closePrice)
      if (closePrice > r.maxPrice) closePrice = r.maxPrice
      if (!closePrice && r.history && r.history?.length) {
        const last = r.history[r.history.length - 1]
        closePrice = last.price
      }
    } else {
      // const min = Math.min(r.minPrice, closePrice)
      if (closePrice < r.minPrice) closePrice = r.minPrice
      if (!closePrice && r.history && r.history?.length) {
        const last = r.history[r.history.length - 1]
        closePrice = last.price
      }
    }
  }

  //if (!closePrice) {
  // if (r.history && r.history?.length) {
  //   const closeKey = r.direction === 'long' ? 'closeLong' : 'closeShort';
  //   const closes = r.history.filter(a => a.type === closeKey)

  //   let avgPrice = 0
  //   let totalPosition = 0
  //   for (const enter of closes) {
  //     avgPrice += enter.avgEntryPrice * enter.position
  //     totalPosition += enter.position
  //   }
  //   avgPrice /= totalPosition
  //   closePrice = avgPrice
  // }
  //}

  return { openPrice, closePrice }
}